export const GET_VERIFY_DATA = 'GET_VERIFY_DATA'
export const GET_VERIFY_DATA_SUCCESS = 'GET_VERIFY_DATA_SUCCESS'
export const GET_VERIFY_DATA_FAIL = 'GET_VERIFY_DATA_FAIL'

export const GET_PROVENANCE = 'GET_PROVENANCE'
export const GET_PROVENANCE_SUCCESS = 'GET_PROVENANCE_SUCCESS'
export const GET_PROVENANCE_FAIL = 'GET_PROVENANCE_FAIL'

export const GET_OWNER = 'GET_OWNER'
export const GET_OWNER_SUCCESS = 'GET_OWNER_SUCCESS'
export const GET_OWNER_FAIL = 'GET_OWNER_FAIL'

export const SAVE_ACCOUNT = 'SAVE_ACCOUNT'
export const SAVE_ACCOUNT_SUCCESS = 'SAVE_ACCOUNT_SUCCESS'
export const SAVE_ACCOUNT_FAIL = 'SAVE_ACCOUNT_FAIL' 

export const GET_INSPECTION_REPORT = 'GET_INSPECTION_REPORT'
export const GET_INSPECTION_REPORT_SUCCESS = 'GET_INSPECTION_REPORT_SUCCESS'
export const GET_INSPECTION_REPORT_FAIL = 'GET_INSPECTION_REPORT_FAIL'