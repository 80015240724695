import { takeLatest, put, call } from 'redux-saga/effects';
import { GET_VERIFY_DATA } from '../constants/types';
import { getVerifyDataSuccess, getVerifyDataFail } from '../actions';
import { LINK_API } from 'constants/ApiConstant';
import { fetchData } from 'utils';

function* getVerifyData(requestData) {
  try {
    let url = LINK_API.GET_VERIFY_DATA;
    const response = yield call(fetchData, 'get', url, requestData.payload);
    const data = response.data;
    yield put(getVerifyDataSuccess(data));
  } catch (error) {
    yield put(getVerifyDataFail(error));
  }
}

const Verify = [
  takeLatest(GET_VERIFY_DATA, getVerifyData),
];
export default Verify;
