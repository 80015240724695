import {
  GET_VERIFY_DATA,
  GET_VERIFY_DATA_SUCCESS,
  GET_VERIFY_DATA_FAIL,
} from 'redux/constants/types';

export const getVerifyData = (params) => {
  return {
    type: GET_VERIFY_DATA,
    payload: params,
  };
};

export const getVerifyDataSuccess = (data) => {
  return {
    type: GET_VERIFY_DATA_SUCCESS,
    data,
  };
};

export const getVerifyDataFail = (error) => {
  return {
    type: GET_VERIFY_DATA_FAIL,
    error,
  };
};
