import { env } from 'configs/EnviromentConfig'
// const API_BASE_URL = env.API_ENDPOINT_URL
const DOMAIN_URL = env.DOMAIN_URL

const LINK_API = {
    GET_VERIFY_DATA: DOMAIN_URL + '/api/v1/certificate/verify',
    GET_PROVENANCE: '/api/v1/read-events',
    GET_OWNER: '/api/v1/read-events/owner-of',
    GET_INSPECTION_REPORT: '/api/v1/certificate/inspection-reports'
}

export {
    LINK_API
}