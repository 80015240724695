import {
  GET_OWNER,
  GET_OWNER_SUCCESS,
  GET_OWNER_FAIL,
} from 'redux/constants/types';

export const getOwner = (params) => {
  return {
    type: GET_OWNER,
    payload: params,
  };
};

export const getOwnerSuccess = (data) => {
  return {
    type: GET_OWNER_SUCCESS,
    data,
  };
};

export const getOwnerFail = (error) => {
  return {
    type: GET_OWNER_FAIL,
    error,
  };
};
