import { takeLatest, put, call } from 'redux-saga/effects';
import { GET_OWNER } from '../constants/types';
import { getOwnerSuccess, getOwnerFail } from '../actions';
import { LINK_API } from 'constants/ApiConstant';
import { fetchData } from 'utils';
import { env } from 'configs/EnviromentConfig';

function* getOwner(requestData) {
  try {
    let url = env.API_ENDPOINT_URL[requestData.payload.origin || 'Production'] + LINK_API.GET_OWNER;
    const response = yield call(fetchData, 'post', url, { body: requestData.payload });
    const data = response.data?.data;
    yield put(getOwnerSuccess(data));
  } catch (error) {
    yield put(getOwnerFail(error));
  }
}

const Owner = [
  takeLatest(GET_OWNER, getOwner),
];
export default Owner;
