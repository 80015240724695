import {
  GET_INSPECTION_REPORT,
  GET_INSPECTION_REPORT_SUCCESS,
  GET_INSPECTION_REPORT_FAIL,
} from 'redux/constants/types';

export const getInspectionReport = (params) => {
  return {
    type: GET_INSPECTION_REPORT,
    payload: params,
  };
};

export const getInspectionReportSuccess = (data) => {
  return {
    type: GET_INSPECTION_REPORT_SUCCESS,
    data,
  };
};

export const getInspectionReportFail = (error) => {
  return {
    type: GET_INSPECTION_REPORT_FAIL,
    error,
  };
};
