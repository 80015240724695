import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

/*
 * @ All pages Import
 */
import ScrollToTop from "./helpers/ScrollToTop";
import Loader from "./components/Loader";
import { Provider } from "react-redux";
import store from "./redux/store";
import { CookiesProvider } from "react-cookie";
// Multilanguage
import { config as i18nextConfig } from './translations/index.js';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';


i18next.init(i18nextConfig);

const Home = lazy(() => import("./pages/home-page/index"));

const App = () => {
  return (
    <Suspense fallback={<Loader />}>
      <CookiesProvider>
        <Provider store={store}>
          <I18nextProvider i18n={i18next}>
            <Router>
              <ScrollToTop>
                <Routes>
                  <Route exact path={`/`} element={<Home />} />
                  <Route exact path={`/verify`} element={<Home />} />
                  <Route exact path={`/verify/:tx_hash`} element={<Home />} />
                  <Route exact element={<Home />}/>
                </Routes>
              </ScrollToTop>
            </Router>
          </I18nextProvider>
        </Provider>
      </CookiesProvider>
    </Suspense>
  );
};

export default App;