import { takeLatest, put, call } from 'redux-saga/effects';
import { GET_PROVENANCE } from '../constants/types';
import { getProvenanceSuccess, getProvenanceFail } from '../actions';
import { LINK_API } from 'constants/ApiConstant';
import { fetchData } from 'utils';
import { env } from 'configs/EnviromentConfig';

function* getProvenance(requestData) {
  try {
    let url = env.API_ENDPOINT_URL[requestData.payload.origin || 'Production'] + LINK_API.GET_PROVENANCE;
    const response = yield call(fetchData, 'post', url, { body: requestData.payload });
    const data = response.data?.data;
    yield put(getProvenanceSuccess(data));
  } catch (error) {
    yield put(getProvenanceFail(error));
  }
}

const Verify = [
  takeLatest(GET_PROVENANCE, getProvenance),
];
export default Verify;
