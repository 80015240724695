const dev = {
  API_ENDPOINT_URL: {
    "Staging": process.env.REACT_APP_STAGING_DASH_HOST,
    "Production": process.env.REACT_APP_PROD_DASH_HOST,
  },
  DOMAIN_URL: process.env.REACT_APP_DOMAIN
};

const prod = {
  API_ENDPOINT_URL: {
    "Staging": process.env.REACT_APP_STAGING_DASH_HOST,
    "Production": process.env.REACT_APP_PROD_DASH_HOST,
  },
  DOMAIN_URL: process.env.REACT_APP_DOMAIN
};

const test = {
  API_ENDPOINT_URL: {
    "Staging": process.env.REACT_APP_STAGING_DASH_HOST,
    "Production": process.env.REACT_APP_PROD_DASH_HOST,
  },
  DOMAIN_URL: process.env.REACT_APP_DOMAIN
};

const getEnv = () => {
  switch (process.env.REACT_APP_ENV) {
    case "development":
      return dev;
    case "staging":
      return dev;
    case "production":
      return prod;
    case "test":
      return test;
    default:
      break;
  }
};

export const env = getEnv();
