import {
  GET_VERIFY_DATA,
  GET_VERIFY_DATA_SUCCESS,
  GET_VERIFY_DATA_FAIL,
} from '../constants/types';

const initState = {
  loading: false,
  data: '',
  error: '',
};

const verify = (state = initState, action) => {
  switch (action.type) {
    case GET_VERIFY_DATA:
      return {
        ...state,
        loading: true,
      };
    case GET_VERIFY_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: ''
      };
    case GET_VERIFY_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        data: ''
      };
    default:
      return state;
  }
};

export default verify;
