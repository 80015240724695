import {
    SAVE_ACCOUNT
  } from '../constants/types';
  
  const initState = {
    loading: false,
    data: '',
    error: '',
  };
  
  const owner = (state = initState, action) => {
    switch (action.type) {
      case SAVE_ACCOUNT:
        return {
          ...state,
          data: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default owner;
  