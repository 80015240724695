import {
    GET_PROVENANCE,
    GET_PROVENANCE_SUCCESS,
    GET_PROVENANCE_FAIL,
  } from 'redux/constants/types';
  
  export const getProvenance = (params) => {
    return {
      type: GET_PROVENANCE,
      payload: params,
    };
  };
  
  export const getProvenanceSuccess = (data) => {
    return {
      type: GET_PROVENANCE_SUCCESS,
      data,
    };
  };
  
  export const getProvenanceFail = (error) => {
    return {
      type: GET_PROVENANCE_FAIL,
      error,
    };
  };
  