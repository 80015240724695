import { takeLatest, put, call } from 'redux-saga/effects';
import { GET_INSPECTION_REPORT } from '../constants/types';
import { getInspectionReportSuccess, getInspectionReportFail } from '../actions';
import { LINK_API } from 'constants/ApiConstant';
import { fetchData } from 'utils';
import { env } from 'configs/EnviromentConfig';

function* getInspectionReport(requestData) {
  try {
    let url = env.DOMAIN_URL + LINK_API.GET_INSPECTION_REPORT;
    const response = yield call(fetchData, 'get', url, { params: requestData.payload });
    const data = response.data;
    yield put(getInspectionReportSuccess(data));
  } catch (error) {
    yield put(getInspectionReportFail(error));
  }
}

const Verify = [
  takeLatest(GET_INSPECTION_REPORT, getInspectionReport),
];
export default Verify;