import { Cookies } from 'react-cookie'
import axios from 'axios'

function fetchData(method, url, payload) {
  return axios({
    headers: {
      "marketplace-key":  "mynameiskhoicutehehehee"
    },
    method: method,
    url: url,
    params: payload && payload.params,
    data: payload && payload.body
  })
}

const onChangeLang = (props) => {
  const { i18n } = props
  const lang = i18n.language;
  const cookies = new Cookies()

  if (props.altLang) {
    i18n.changeLanguage(props.altLang)
    cookies.set('language', props.altLang)
  } else {
    if (lang === 'en') {
      i18n.changeLanguage('vi')
      cookies.set('language', 'vi')
    } else {
      i18n.changeLanguage('en')
      cookies.set('language', 'en')
    }
  }
}

const generateAction = (type) => {
  return (payload) => ({
    type,
    payload,
  });
};

const stripHtml = (html) =>
{
   let tmp = document.createElement("DIV");
   tmp.innerHTML = html;
   return tmp.textContent || tmp.innerText || "";
}

const truncate = (str, n) => {
  return (str && str.length > n) ? str.substr(0, n-1) + '...' : str;
}

export { generateAction, stripHtml, truncate, onChangeLang, fetchData };
