import { combineReducers } from 'redux'
import verify from './Verify'
import provenance from './Provenance'
import owner from './Owner'
import account from './Account'
import inspectionReport from './InspectionReport'

const reducers = combineReducers({
    verify,
    provenance,
    owner,
    account,
    inspectionReport
});

export default reducers;